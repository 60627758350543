import { useQuery } from '@tanstack/react-query';
import * as Backend from 'queries/BackendRequest';

interface Props {
  token: string | null;
}

const getPatchEmail = async ({ token }: Props) => {
  if (!token) {
    throw Error('No token provided');
  }
  await Backend.request('get', '/updateEmail', { token }, {});
  return null;
};

const getUserPatchEmail = (props: Props) => ({
  queryKey: ['getUserPatchEmail', props.token],
  queryFn: () => getPatchEmail(props),
});

const useGetUserPatchEmail = (props: Props) => {
  return useQuery(getUserPatchEmail(props));
};

export default useGetUserPatchEmail;
