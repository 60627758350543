import { Button, Typography } from '@mui/material';
import FormContainer from 'components/formContainer/FormContainer';
import FormLogo from 'components/formLogo/FormLogo';
import React from 'react';

interface FormProps {
  handleClick: () => void;
}

const Form: React.FC<FormProps> = ({ handleClick }) => {
  return (
    <>
      <FormContainer elevation={4}>
        <FormLogo />
        <Typography variant="h6" style={{ marginTop: '16px' }}>
          Thank you!
        </Typography>
        <p style={{ marginTop: '5px', marginBottom: '16px' }}>Your e-mail has been successfully updated!</p>
        <Button
          style={{ width: '100%', marginBottom: '40px' }}
          onClick={handleClick}
          variant="contained"
          color="secondary"
        >
          Continue
        </Button>
      </FormContainer>
    </>
  );
};

export default Form;
