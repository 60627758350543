import { CircularProgress } from '@mui/material';
import useProjectId from 'hooks/useProjectId';
import useGetUserPatchEmail from 'queries/user/useGetUserPatchEmail';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Form from './components/Form';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';

const PatchEmail: React.FC = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const updateEmail = useGetUserPatchEmail({ token: new URLSearchParams(window.location.search).get('token') });

  const handleClick = () => {
    navigate(`/${projectId}/login`);
  };

  if (updateEmail.isLoading) return <CircularProgress color="secondary" />;

  return (
    <>
      <Content greyBackground>
        <Form handleClick={handleClick} />
      </Content>
      <Footer />
    </>
  );
};

export default PatchEmail;
